import './App.css';
import React from 'react';
import { AuthProvider, Descope } from '@descope/react-sdk';
import intro from './logo-intro.png';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as LogoIcon } from './logo-icon.svg';

const projectRegex = /^P[a-zA-Z0-9]{27}$/;

const PROJECTS: string[] = [
	'P2Ps4V3hSSulSbjCCCYA6pIYhunV',
	'P2Ps6Uer3UiG6eQoGB3FwHhI4Zme',
	'P2Oc547EeJrJPotZHmxRKZa98n0W'
];

const App = () => {
	let baseUrl = process.env.REACT_APP_DESCOPE_BASE_URL;

	if (process.env.REACT_APP_USE_ORIGIN_BASE_URL)
		baseUrl = window.location.origin;

	// first, take project id from env
	const envProjectId = projectRegex.exec(
		process.env.DESCOPE_PROJECT_ID ?? PROJECTS[0]
	)?.[0];

	// If exists in URI use it, otherwise use env
	const pathnameProjectId = projectRegex.exec(
		window.location.pathname?.split('/').at(-1) || ''
	)?.[0];

	const projectId = pathnameProjectId ?? envProjectId ?? null;

	return (
		<div className="app">
			<header>
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<a href="https://app.nominal.so/" className="logo-link">
					<Logo height={32} width={158} fill="#EDF1F8" title="Nominal logo" />
				</a>
			</header>
			<div className="login-card">
				<div className="descope-container" data-testid="descope-component">
					{projectId && PROJECTS.includes(projectId) && (
						<AuthProvider projectId={projectId} baseUrl={baseUrl}>
							<Descope flowId="sign-up-or-in" />
						</AuthProvider>
					)}
				</div>
				<img alt="Nominal login" src={intro} />
			</div>
			<footer>
				<LogoIcon
					width={20}
					height={19}
					fill="#EDF1F8"
					title="Nominal logo icon"
				/>
				<span>© 2024 Nominal ERP, Inc. All rights reserved.</span>
			</footer>
		</div>
	);
};

export default App;
